import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "@/lang";
import 'vant/lib/index.css';
import './assets/css/common.css'
import 'viewerjs/dist/viewer.css';
import { Icon, Swipe, SwipeItem, PasswordInput, NumberKeyboard, Loading, Empty, Tab, Tabs, Popup, Toast, Cell, CellGroup, ImagePreview, Button, Dialog, DatePicker, Uploader, ActionSheet, Checkbox } from 'vant';
import '@vant/touch-emulator';
import QrImage from '@/components/QrImage.vue';
import QrLoading from '@/components/QrLoading.vue';
import RichText from '@/components/RichText.vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';


const app = createApp(App);

app.use(Icon);
app.use(Swipe);
app.use(SwipeItem);
app.use(PasswordInput);
app.use(NumberKeyboard);
app.use(Loading);
app.use(Empty);
app.use(Tab);
app.use(Tabs);
app.use(Popup);
app.use(Toast);
app.use(Cell);
app.use(CellGroup);
app.use(ImagePreview);
app.use(Button);
app.use(Dialog);
app.use(DatePicker);
app.use(Uploader);
app.use(ActionSheet);
app.use(Checkbox);
app.use(VueDOMPurifyHTML);
app.use(store).use(i18n).use(router).mount('#app')

app.component('QrImage', QrImage);
app.component('QrLoading', QrLoading);
app.component('RichText', RichText);

console.log = (function (log) {
    return process.env.VUE_APP_NO_CONSOLE == "1" ? function () { } : log;
})(console.log);

function setRootFontSize () {
    const designWidth = 750; // 设计稿宽度
    const remBase = 1; // rem 基准值
    let screenWidth = window.innerWidth; // 屏幕宽度
    if (screenWidth > designWidth) { // 如果屏幕宽度大于设计稿宽度，设置屏幕宽度为设计稿宽度
        screenWidth = designWidth;
    }
    const scale = screenWidth / designWidth; // 缩放比例
    const rootFontSize = scale * remBase; // 计算根元素字体大小
    document.documentElement.style.fontSize = rootFontSize + 'px'; // 设置根元素字体大小
}

// 防抖
let timer = null;
function debounce (fn, delay) {
    if (timer) {
        clearTimeout(timer);
    }
    timer = setTimeout(fn, delay);
}

// 窗口变化防抖
function debounceResize () {
    debounce(setRootFontSize, 100);
}

// 页面加载时设置根元素字体大小
setRootFontSize();

// 监听窗口大小变化时重新设置根元素字体大小
window.addEventListener('resize', debounceResize);

app.config.globalProperties.$i18n = i18n;


function formatDate (date_, format, utc) {
    const date = new Date(date_)
    var MMMM = ["\x00", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var MMM = ["\x01", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var dddd = ["\x02", "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    function ii (i, len) { var s = i + ""; len = len || 2; while (s.length < len) s = "0" + s; return s; }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();
    format = format.replace(/(^|[^\\])yyyy+/g, "$1" + y);
    format = format.replace(/(^|[^\\])yy/g, "$1" + y.toString().substr(2, 2));
    format = format.replace(/(^|[^\\])y/g, "$1" + y);

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])MMMM+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])MMM/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])MM/g, "$1" + ii(M));
    format = format.replace(/(^|[^\\])M/g, "$1" + M);

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])dddd+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])ddd/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])dd/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])HH+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])hh+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])h/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])mm+/g, "$1" + ii(m));
    format = format.replace(/(^|[^\\])m/g, "$1" + m);

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])ss+/g, "$1" + ii(s));
    format = format.replace(/(^|[^\\])s/g, "$1" + s);

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])fff+/g, "$1" + ii(f, 3));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])ff/g, "$1" + ii(f));
    f = Math.round(f / 10);
    format = format.replace(/(^|[^\\])f/g, "$1" + f);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])TT+/g, "$1" + T);
    format = format.replace(/(^|[^\\])T/g, "$1" + T.charAt(0));

    var t = T.toLowerCase();
    format = format.replace(/(^|[^\\])tt+/g, "$1" + t);
    format = format.replace(/(^|[^\\])t/g, "$1" + t.charAt(0));

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
        tz = Math.abs(tz);
        var tzHrs = Math.floor(tz / 60);
        var tzMin = tz % 60;
        K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");

    return format;
};

const formatJPY = (value, addMark) => {
    if (value === undefined || value === null) {
        return ''
    }
    const formatter = new Intl.NumberFormat('ja-JP');
    let formatN = formatter.format(value);
    if (addMark) {
        formatN = '￥' + formatN
    }
    return formatN
}

app.config.globalProperties.$formatDate = formatDate;
app.config.globalProperties.$formatJPY = formatJPY;
window.$formatDate = formatDate;
window.$formatJPY = formatJPY;

if (window.location.hash === '#vieweropen') {
    // 去掉hash
    setTimeout(() => {
        window.location.hash = ''
    }, 300)
}